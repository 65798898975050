@import "./../mixins/mixins";

.graphic-background-bg-illustration-promotion{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .graphic-background{
        &-left{
            margin-right: auto;
            min-width: max-content;
            @supports (-webkit-hyphens:none){
                height: intrinsic;
            }  
            @media (max-width: 1920px){
                margin-left: calc((((1920px - 100vw)* -1) / 2));
            }
        }
        &-right{
            margin-left: auto;
            min-width: max-content;
            @supports (-webkit-hyphens:none){
                height: intrinsic;
            }
            @media (max-width: 1920px){
               margin-right: calc((((1920px - 100vw)* -1) / 2));
            }
        }
    }
    &[data-animation-css]{
        &:not(.is-animated){
            [data-top-left-animation],
            [data-top-right-animation],
            [data-animation-path],
            [data-animation-cube] {
                animation: unset!important
            }
        }
        &.is-paused{
            [data-animation-cube],
            [data-animation-coin],
            [data-animation-dot],
            [data-animation-vertical-loop-sm],
            [data-animation-vertical-loop],
            [data-animation-up-down],
            [data-animation-down-up] {
                // animation: unset!important
                animation-play-state: paused;
            }
        }
        [data-top-left-animation],
        [data-top-right-animation],
        [data-animation-cube] {
            opacity: 0;
        }
        &.is-animated{ 
            [data-top-left-animation]{
                animation-name: showElementTopLeft-promotion;
                animation-duration: 1s;
                animation-timing-function: cubic-bezier(0, 0, 0, 1);
                animation-fill-mode: forwards;
            }
            [data-top-right-animation]{
                animation-name: showElementTopRight-promotion;
                animation-duration: 1s;
                animation-timing-function: cubic-bezier(0, 0, 0, 1);
                animation-fill-mode: forwards;
            }
            [data-animation-delay-1]{
                animation-delay: 0s;
            }
            [data-animation-delay-2]{
                animation-delay: 0.12s;
            }
            [data-animation-delay-3]{ 
                animation-delay: 0.24s;
            }
            [data-animation-cube]{
                will-change: transform, opacity;
                animation-name: cubeLoop-promotion;
                animation-duration: 2.20s;
                animation-delay: 1.08s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-fill-mode: forwards;
                &:nth-child(1){
                    animation-delay: 0.32s;
                }
                &:nth-child(2){
                    animation-delay: 0.96s;
                }
                &:nth-child(3){
                    animation-delay: 1.24s;
                }
                &[data-animation-cube-1]{
                    --cubeStart: 60px;
                    --cubeGap: -50px;
                }
                &[data-animation-cube-2]{
                    --cubeStart: 40px;
                    --cubeGap: -70px;
                }
                &[data-animation-cube-3]{
                    --cubeStart: 20px;
                    --cubeGap: -70px;
                }
                &[data-animation-cube-sm-1]{
                    --cubeStart: 60px;
                    --cubeGap: -25px;
                }
                &[data-animation-cube-sm-2]{
                    --cubeStart: 50px;
                    --cubeGap: -35px;
                }
                &[data-animation-cube-sm-3]{
                    --cubeStart: 50px;
                    --cubeGap: -35px;
                }
            }
            [data-animation-path]{
                will-change: transform, opacity;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                &[data-animation-path-1]{
                    animation-name: pathAnimation1-promotion;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-2]{
                    animation-name: pathAnimation2-promotion;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-3]{
                    animation-name: pathAnimation3-promotion;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-4]{
                    animation-name: pathAnimation4-promotion;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-5]{
                    animation-name: pathAnimation5-promotion;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-6]{
                    animation-name: pathAnimation6-promotion;
                    animation-duration: 0.6s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-7]{
                    animation-name: pathAnimation7-promotion;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-8]{
                    animation-name: pathAnimation8-promotion;
                    animation-duration: 1.3s;
                    animation-delay: 1.08s;
                }
                &[data-animation-path-9]{
                    animation-name: pathAnimation9-promotion;
                    animation-duration: 1s;
                    animation-delay: 1.08s;
                }
            }
        }
    }
    @keyframes pathAnimation1-promotion {from {stroke-dashoffset: 530;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation2-promotion {from {stroke-dashoffset: 320;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation3-promotion {from {stroke-dashoffset: 255;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation4-promotion {from {stroke-dashoffset: 100;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation5-promotion {from {stroke-dashoffset: 120;}to{stroke-dashoffset: 0;}}
    @keyframes pathAnimation6-promotion {from {stroke-dashoffset: 380;}to{stroke-dashoffset: 0;}}
    
    @keyframes pathAnimation7-promotion {from {stroke-dashoffset: 642;}to{stroke-dashoffset: 0;}}

    @keyframes showElementTopLeft-promotion {
        from {
            transform: translate3d(52px,30px, 0px);
            opacity: 0;
        }
        to {
            transform: translate3d(0,0, 0px);
            opacity: 1;
        }
    }
    @keyframes showElementTopRight-promotion {
        from {
            transform: translate3d(-52px,30px, 0px);
            opacity: 0;
        }
        to {
            transform: translate3d(0,0, 0px);
            opacity: 1;
        }
    }
    @keyframes cubeLoop-promotion {
        0% {
            transform: translate3d(0px, var(--cubeStart), 0px);
            opacity: 0
        }
    
        10%,60% {
            opacity: 1
        }
    
        to {
            transform: translate3d(0px, var(--cubeGap), 0px);
            opacity: 0
        }
    }
}


